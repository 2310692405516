import { Button, Typography, Image, Picture } from "@/ui";
import { IconAIStar, IconAIStars } from "@/icons";

import useStyles from "./styles";

const BannerAiPromo = ({ isHome }) => {
  const classes = useStyles();

  return (
    <aside className={`${classes.root} ${isHome ? classes.rootHome : ""}`}>
      <Picture
        alt="Hero"
        loading="eager"
        fetchpriority="high"
        decoding="auto"
        defaultSizeIndex={2}
        className={`${classes.hero} ${isHome ? classes.heroHome : ""}`}
        sizes={{
          md: {
            src: isHome
              ? "https://res.cloudinary.com/atlasguruprod/image/upload/dpr_auto,f_auto,q_auto,fl_progressive,c_crop,w_1050,h_670,x_0,y_0/v1/site-prod/misc/i9vr9vccuu2ey2o2iw7r"
              : "https://res.cloudinary.com/atlasguruprod/image/upload/dpr_auto,fl_progressive,f_auto,q_auto/v1/site-prod/misc/i9vr9vccuu2ey2o2iw7r",
            width: 1168,
            height: 657,
          },
          sm: {
            src: "https://res.cloudinary.com/atlasguruprod/image/upload/dpr_auto,f_auto,q_auto,fl_progressive,c_crop,w_1050,h_670,x_0,y_0/v1/site-prod/misc/i9vr9vccuu2ey2o2iw7r",
            width: 960,
            height: 540,
          },
          xs: {
            src: "https://res.cloudinary.com/atlasguruprod/image/upload/f_auto,q_auto:eco/v1/site-prod/misc/tfaajicgo4jyovk0fvmx",
            width: 390,
            height: 542,
          },
        }}
      />

      <div
        className={`${classes.content} ${isHome ? classes.contentHome : ""}`}
      >
        <div className={classes.left}>
          <IconAIStars
            classes={{ root: classes.smallUp }}
            size="lg"
            viewBox="0 0 50 50"
          />

          <div>
            <Typography
              component="p"
              variant="h1"
              className={`${classes.heading} ${classes.smallUp} ${
                isHome ? classes.headingHome : ""
              }`}
            >
              Your AI Travel Advisor
            </Typography>

            <Typography
              component="p"
              variant="h1"
              className={`${classes.heading} ${classes.extraSmallDown} ${
                isHome ? classes.headingHome : ""
              }`}
            >
              <span>Your AI Travel</span>{" "}
              <span className={classes.smallIcon}>
                <span>Advisor</span>{" "}
                <IconAIStars size="md" viewBox="0 0 50 50" />
              </span>
            </Typography>

            <Typography className={classes.message} variant="caption">
              {`Build your trip for free with AI. Then book it with `}
              <br className={classes.smallUp} />
              {`a travel advisor to make it real.`}
            </Typography>
          </div>
        </div>
        <div className={`${classes.right} ${isHome ? classes.rightHome : ""}`}>
          <div className={classes.avatarContainer}>
            <Image
              src="https://res.cloudinary.com/atlasguruprod/image/upload/dpr_auto,fl_progressive,f_auto,q_auto,w_30,h_30/v1/site-prod/misc/how-it-works-avatar-01.1e95a037dde9006f2885ec047f2a8481"
              className={classes.userAvatarImg}
              height={30}
              width={30}
            />
            <Image
              src="https://res.cloudinary.com/atlasguruprod/image/upload/dpr_auto,fl_progressive,f_auto,q_auto,w_30,h_30/v1/site-prod/misc/how-it-works-avatar-09.d85f3861ea3b3f66d8d5d9e539783e3b"
              className={classes.userAvatarImg}
              height={30}
              width={30}
            />
            <Image
              src="https://res.cloudinary.com/atlasguruprod/image/upload/dpr_auto,fl_progressive,f_auto,q_auto,w_30,h_30/v1/site-prod/misc/how-it-works-avatar-03.d7899bf9944a220e5ac6f96ffb61c333"
              className={classes.userAvatarImg}
              height={30}
              width={30}
            />
            <Image
              src="https://res.cloudinary.com/atlasguruprod/image/upload/dpr_auto,fl_progressive,f_auto,q_auto,w_30,h_30/v1/site-prod/misc/how-it-works-avatar-04.a8caeafad1fd5ed02d9961eaf8c90888"
              className={classes.userAvatarImg}
              height={30}
              width={30}
            />
            <Image
              src="https://res.cloudinary.com/atlasguruprod/image/upload/dpr_auto,fl_progressive,f_auto,q_auto,w_30,h_30/v1/site-prod/misc/how-it-works-avatar-05.6cf00e2918fd06f50c0faeede649fdf6"
              className={classes.userAvatarImg}
              height={30}
              width={30}
            />
          </div>

          <Button
            href="/ai-itinerary-planner/"
            className={classes.button}
            variant="contained"
            classes={{
              label: classes.buttonLabel,
            }}
          >
            <IconAIStar size="sm" viewBox="0 0 45 45" />
            Try It Now
          </Button>
        </div>
      </div>
    </aside>
  );
};

export default BannerAiPromo;
